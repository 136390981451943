import React from "react";

const Copyright = () => {
  return (
    <div className="text-white bg-primary border-top px-2 d-flex align-items-center justify-content-center">
      <span>
        مؤسسة السهم السريع جميع الحقوق محفوظة &copy;{new Date().getFullYear()}
      </span>
    </div>
  );
};

export default Copyright;
